import { qs, qsa } from '../utils/lib'
import React, { Component } from 'react'
import Store from './store'
import _ from 'lodash'
import Sync from './cart_sync';
import Wrap from './wrap.jsx';

export default class Recap extends Component {

  state = {
    ...Store.getProps(['cart_count']),
    ...Store.getProps(['cart_open']),
    ...Store.getProps(['cart_items']),
    ...Store.getProps(['close_cart']),
    ...Store.getProps(['cart_total']),
    ...Store.getProps(['checkout']),
    translations: JSON.parse(this.props.translations),
    copy: JSON.parse(this.props.copy),
    animating: false,
    loading: false,
    total: 0,
    notice: this.props.b2b ? this.props.b2b_message : this.props.notice,
    totalQuantity: 0
  }

  async componentDidMount() {
    Store.linkState(this, ['cart_count'])
    Store.linkState(this, ['cart_open'])
    Store.linkState(this, ['cart_items'])
    Store.linkState(this, ['close_cart'])
    Store.linkState(this, ['cart_total'])
    Store.linkState(this, ['checkout'])


    this.calcTotal()
    this.setButton()
  }
  componentDidUpdate(prevProps, prevState) {
    if (Store.getProps(['add_to_cart']).add_to_cart) {
      //this.open()
      Store.set("add_to_cart", false)
      this.calcTotal()
    }

    if (this.state.close_cart) {
      //this.close()
      this.setState({
        close_cart: false
      })
    }
    localStorage.setItem('lc_cart', JSON.stringify(this.state.cart_items));
    new Sync(false)


  }

  async addItem(e) {

    if (this.state.quantity === 0 || this.state.product.sizes[this.state.current_size].qty == 0 || this.state.product.sizes[this.state.current_size].qty < 0 || this.state.prompt) return

    if (typeof gtag !== 'undefined') {
      gtag('event', 'add_to_cart', {
        "items": [this.state.product.to_google]
      });
      gtag('event', 'add', {
        'event_category': 'cart'
      });
    }



    let itemToAdd = {
      "id": this.state.product.product_id,
      "size": this.state.product.sizes[this.state.current_size],
      "title": this.state.product.title,
      "price_without_taxes": this.state.product.price_without_taxes,
      "price": this.state.product.item_price,
      "discount": 0,
      "discount_without_taxes": 0,
      "weight": this.state.product.weight,
      "compare_price": this.state.product.compare_price,
      "image": this.state.product.image,
      "quantity": this.state.quantity,
      "to_google": this.state.product.to_google
    }


    let cartItems = this.state.cart_items

    if (cartItems.length > 0) {

      let ext = cartItems.find(element => element.size.id == itemToAdd.size.id);
      if (ext) {
        if (ext.quantity == this.state.product.sizes[this.state.current_size].qty) {
          this.setState({
            cart_cta: "No more stock"
          })
          return
        }
        ext.quantity = itemToAdd.quantity + ext.quantity
      } else {
        cartItems.push(itemToAdd)
      }
    } else {
      cartItems.push(itemToAdd)
    }



    cartItems.reverse()

    this.setState({
      cart_items: cartItems
    })
    Store.set("cart_items", cartItems)
    Store.set("add_to_cart", true)
    Store.set("cart_total", this.state.cart_total + this.state.quantity)
    localStorage.setItem('lc_cart', JSON.stringify(this.state.cart_items));

    setTimeout(() => {
      this.setState({
        cart_cta: this.state.add_cta
      })
    }, 3000)

  }
  calcItems() {
    const cartItemsQty = this.state.cart_items.map((cartItem) => {
      return cartItem.quantity
    })
    if (cartItemsQty.length > 0) {
      this.setState({ totalQuantity: cartItemsQty.reduce((a, b) => a + b) })
    }

  }


  add(i) {

    let items = this.state.cart_items
    if (items[i].quantity == items[i].size.qty) {
      this.setButton()
      return
    }
    items[i].quantity += 1
    const qty_string = qsa(".js-item-quantity")
    qty_string[i].innerHTML = items[i].quantity
    this.setState({
      cart_items: items
    })
    this.calcTotal()
    this.calcItems()
  }

  change(i, el) {
    if (parseInt(el.target.value) && parseInt(el.target.value) > 0) {
      let items = this.state.cart_items
      items[i].quantity = parseInt(el.target.value)
      this.setState({
        cart_items: items
      })
      this.calcTotal()
    } else {
      return false
    }


  }

  blur(i, el) {
    if (parseInt(el.target.value) && parseInt(el.target.value) > 0) {

      return false

    } else {
      let items = this.state.cart_items
      el.target.value = items[i].quantity
      return false
    }
  }

  remove(i) {
    let items = this.state.cart_items
    items[i].quantity -= 1
    if (items[i].quantity == 0) {
      items.splice(i, 1)

      this.setState({
        cart_items: items
      })

    } else {
      this.setState({
        cart_items: items
      })
    }

    this.calcTotal()


  }
  delete(i) {
    let items = this.state.cart_items
    items[i].quantity = 0
    console.log(items[i].quantity == 0)
    if (items[i].quantity == 0) {
      items.splice(i, 1)

      this.setState({
        cart_items: items
      })

    }

    this.calcTotal()

  }
  calcPrice() {
    let priceArray = []
    let total

    this.state.cart_items.forEach((el, i) => {
      let sum = parseFloat(el.price) * el.quantity

      priceArray.push(sum)
    })

    return priceArray.reduce((a, b) => a + b, 0)
  }

  calcTotal() {
    let total = 0
    this.state.cart_items.forEach((el, i) => {
      let sum = el.price * el.quantity
      total = total + sum
    })
    Store.set("cart_total", total)
    if (total == 0 && this.state.cart_open) {
      this.close()
    }
    this.calcItems()

  }
  setButton() {
    let items = this.state.cart_items
    items.forEach((item, i) => {
      if (item.quantity == item.size.qty) {
        qsa('.add')[i].setAttribute('disabled', '')
        return
      } else {
        qsa('.add')[i].removeAttribute('disabled')
      }
    })
  }


  render() {

    return (
      <>
        <div className="container">
          <div className="checkout">
            {
              this.state.cart_items.length == 0 ? (
                <section className='text-center'>
                  <h2 className='text-center h1 c-dark'>Your bag is currently empty.</h2>
                  <br />
                  <a href="/" className='underline h5 brown c-dark'>Return to Homepage</a>
                </section>
              ) : (
                <>
                  <div className="checkout_title page-title_title text-left">
                    <h2 className="h4 sainte light c-dark">{this.state.copy.bag} ({this.state.totalQuantity})</h2>
                  </div>

                  <span className="checkout_progress"></span>
                  <div className="checkout_grid brown c-dark">
                    <p>{this.state.copy.product}</p>
                    <p>{this.state.copy.price}</p>
                    <p>{this.state.copy.quantity}</p>
                    <p>{this.state.copy.total}</p>
                  </div>

                  {
                    this.state.cart_items.map((item, i) =>
                      <section key={i} className="checkout-product">
                        <div className="checkout-product_grid">
                          <div className="checkout-product_info">
                            {item.image && (
                              <img loading="lazy" src={item.image} alt={item.title} />
                            )}
                            <span className="checkout-product_name">
                              <h3 className="sainte medium h7">{item.title}</h3>
                              <h4 className="sainte light h7">{item.body}</h4>
                              <br />
                              <p className="brown light h8">Size: {item.size.size}</p>
                              {item.message && (
                                <p className="brown light h8">Message: {item.message}</p>
                              )}
                            </span>
                          </div>
                          <span className="checkout-product_displaynone-mb"><p className="brown light h7">{item.price.toFixed(2)}</p></span>
                          <span className="checkout-product_position-mb">
                            <div className="checkout-product_quantity">
                              {(item.size.upc != "0CHRISTMASWRAP" && !this.props.b2b) && (
                                <span>
                                  <label className="checkout-product_quantity-mb brown h7">{this.state.copy.quantity}: </label>
                                  <button onClick={this.remove.bind(this, i)} className="remove">
                                    <img loading="lazy" src="/icons/minus_circle.svg" alt="" />
                                  </button>
                                  <p className="brown light h7 js-item-quantity">{this.state.cart_items[i].quantity}</p>
                                  <button onClick={this.add.bind(this, i)} className="add">
                                    <img loading="lazy" src="/icons/plus_circle.svg" alt="" />
                                  </button>
                                </span>

                              )}
                              {this.props.b2b && (
                                <span>
                                  <input type="number" onBlur={this.blur.bind(this, i)} defaultValue={this.state.cart_items[i].quantity} min="1" onChange={this.change.bind(this, i)} />

                                </span>

                              )}

                              <button onClick={this.delete.bind(this, i)} className="checkout-product_displaynone-mb button-link button-link-small brown light h7">{this.state.copy.remove}</button>
                            </div>
                          </span>
                          <span className="checkout-product_flex-mb checkout-product_position-mb">
                            <button onClick={this.delete.bind(this, i)} className="checkout-product_remove-mb button-link button-link-small brown light h7">{this.state.copy.remove}</button>
                            <p className="brown h7 c-dark medium text-right">€ {(this.state.cart_items[i].quantity * this.state.cart_items[i].price).toFixed(2)}</p>
                          </span>
                        </div>
                      </section>

                    )
                  }
                  {(this.state.cart_items.length > 0 && this.props.gift && this.props.product && this.state.cart_items.filter((el) => el.size.upc == "0CHRISTMASWRAP").length == 0) && (
                    <div className="gift_wrapping">
                      <details>
                        <summary className="brown c-dark h7 accordion_gift">{this.props.giftcta}</summary>
                        <div className="gift_wrapping_row">
                          <div className="gift_wrapping_col">
                            <p className="brown light">{this.props.wrap}</p>

                          </div>
                          <div className="gift_wrapping_col">
                            {
                              this.props.product && (

                                <Wrap product={this.props.product} cta={this.props.order_cta} />
                              )
                            }
                          </div>
                        </div>
                      </details>

                    </div>
                  )}
                  <div className="checkout_starter">
                    <div className="checkout_cta">

                      <div className="checkout_align c-dark">
                        <p className="brown light">{this.state.copy.subtotal}</p>
                        <p>€ {this.state.cart_total.toFixed(2)}</p>
                      </div>


                      {!this.props.b2b && (
                        <p className="brown light">{this.state.copy.shipping}</p>

                      )}

                      <div className="checkout_align">
                        <a href={this.props.checkout} className="button button-medium bg-dark c-light h8">{this.props.order_cta}</a>
                      </div>




                      <p className="brown light">{this.state.notice}</p>
                    </div>
                  </div>

                </>
              )
            }
          </div>
        </div>
      </>
    )
  }
}
